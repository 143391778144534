import React, { Component } from 'react'
import {Link} from 'react-router-dom'
class OfferBox1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataEvents: [
                {
                    "id": 1,
                    "classicon": "icon-rounded clipboard",
                    "title": "Préstamos Personales",
                    "description": "Facilitamos acceso a crédito flexible para tus necesidades inmediatas. Mejora tu bienestar financiero con nuestras opciones de préstamo."
                },
                {
                    "id": 2,
                    "classicon": "icon-rounded line-chart",
                    "title": "Créditos para Negocios",
                    "description": "Apoyamos a emprendedores con financiamiento para crecimiento y expansión. Potencia tu negocio con nuestros créditos."
                },
                {
                    "id": 3,
                    "classicon": "icon-rounded piggy-bank",
                    "title": "Cuentas de Ahorro",
                    "description": "Ahorra de manera segura y accede a tus fondos fácilmente. Ideal para planificar tus metas financieras."
                }
            ]
        }
    }
    
    render() {
        return (
            <div className="row">
                {
                    this.state.dataEvents.map(data => (
                        <div className="col-md-4" key={data.id}>
                            <div className="iconbox-item">
                                <div className="iconbox style2">
                                    <div className="box-header">
                                        <div className={data.classicon}>
                                            
                                        </div>
                                        {/* <!-- /.icon-rounded --> */}
                                        <div className="box-title">
                                            <Link to="#" title="">{data.title}</Link>
                                        </div>
                                        {/* <!-- /.box-title --> */}
                                        </div>
                                        {/* <!-- /.box-header --> */}
                                    <div className="box-content">{data.description}</div>
                                    {/* <!-- /.box-content --> */}
                                </div>
                                 {/* <!-- /.iconbox --> */}
						    </div>
                            {/* <!-- /.iconbox-item --> */}
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default OfferBox1;