import React, { Component } from 'react'
import {Link} from 'react-router-dom'
class OfferBox2 extends Component {
    constructor(props) {
        super(props);
        this.state ={
            dataEvents: [
         
                {
                    "id": 4,
                    "classicon": "icon-rounded safe",
                    "title": "Depósitos a Plazo Fijo",
                    "description": "Invierte con seguridad y obtén rendimientos garantizados. Perfecto para quienes buscan estabilidad financiera."
                },
                {
                    "id": 5,
                    "classicon": "icon-rounded calendar",
                    "title": "Planes de Ahorro Regular",
                    "description": "Fomenta el ahorro disciplinado con contribuciones constantes. Asegura un futuro financiero sólido y planificado."
                },
                {
                    "id": 6,
                    "classicon": "icon-rounded wallet",
                    "title": "Cuentas Corrientes",
                    "description": "Gestiona tus finanzas diarias con facilidad y acceso rápido a tu dinero. Ideal para transacciones cotidianas."
                }
            ]
        }
    }
    
    render() {
        return (
            <div className="row">
                {
                    this.state.dataEvents.map(data => (
                        <div className="col-md-4" key={data.id}>
                            <div className="iconbox-item">
                                <div className="iconbox style2">
                                    <div className="box-header">
                                        <div className={data.classicon}>
                                            
                                        </div>
                                        {/* <!-- /.icon-rounded --> */}
                                        <div className="box-title">
                                            <Link to="#" title="">{data.title}</Link>
                                        </div>
                                        {/* <!-- /.box-title --> */}
                                        </div>
                                        {/* <!-- /.box-header --> */}
                                    <div className="box-content">{data.description}</div>
                                    {/* <!-- /.box-content --> */}
                                </div>
                                 {/* <!-- /.iconbox --> */}
						    </div>
                            {/* <!-- /.iconbox-item --> */}
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default OfferBox2;