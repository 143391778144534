import React, { useEffect, useRef, useState } from "react";

const Client = () => {
  const [empresa, setEmpresa] = useState([]);
  const [monto, setMonto] = useState(0);
  const [plazo, setPlazo] = useState(0);
  const [tasaInteres, setTasaInteres] = useState(0.238);
  const [seguro, setSeguro] = useState(0);
  const [amortizationTable, setAmortizationTable] = useState([]);
  const [selectedCredito, setSelectedCredito] = useState("Hipo");
  const [sistemaAmortizacion, setSistemaAmortizacion] = useState("aleman");
  const [showLogout, setShowLogout] = useState(false);

  const [totalCuota, setTotalCuota] = useState(0);
  const [totalSeguro, setTotalSeguro] = useState(0);
  const [totalCuotaTotal, setTotalCuotaTotal] = useState(0);
  const [totalInteres, setTotalInteres] = useState(0);
  const [totalCapital, setTotalCapital] = useState(0);

  const tableRef = useRef(null);

  const handleAvatarClick = () => {
    setShowLogout(!showLogout);
  };

  const [creditosList, setCreditosList] = useState([]);

  useEffect(() => {}, []);
  const calculateAmortization = (monto, plazo, tasaInteres, seguro) => {
    try {
      if (seguro === "") {
        seguro = 0;
      }

      setTotalCuota(0);
      setTotalSeguro(0);
      setTotalCuotaTotal(0);
      setTotalInteres(0);
      setTotalCapital(0);
      const amortizationTable = [];
      const tasaMensual = tasaInteres / 100 / 12;
      let saldo = monto;

      const cuotaSinSeguro = saldo / plazo;
      const seguroPorCuota = seguro / plazo;

      for (let i = 1; i <= plazo; i++) {
        let capital = monto / plazo;
        let cuota = 0;
        let seguroCuota = 0.00056;
        let cuotaTotal = 0;
        let interes = 0;
        let pagoSeguro = 0;

        // Únicamente en el primer mes el saldo es igual al monto
        if (i === 1) {
          saldo = monto;
          interes = saldo * 0.02375;
          seguro = saldo * 0.00056;

          cuota = capital + interes + seguro;
          // Calcular la cuotaTotal que es la sumatoria de todos los pagos mensuales menos el último pago
          cuotaTotal = cuota + seguro / plazo;
        } else {
          saldo -= capital;
          interes = saldo * 0.02375;
          seguro = saldo * 0.00056;
          cuota = capital + interes + seguro;
          cuotaTotal = cuota + seguroCuota;
        }

        // Pasar a número el saldo
        saldo = parseFloat(saldo);
        console.log("seguro", seguro);

        amortizationTable.push({
          numeroCuota: i,
          cuota: cuota.toFixed(2),
          seguro: seguro.toFixed(2),
          cuotaTotal: cuotaTotal.toFixed(2),
          interes: interes.toFixed(2),
          capital: capital.toFixed(2),
          saldo: saldo.toFixed(2),
        });

        setTotalCuota((prev) => prev + parseFloat(cuota.toFixed(2)));
        setTotalSeguro((prev) => prev + parseFloat(seguroPorCuota.toFixed(2)));
        setTotalCuotaTotal((prev) => prev + parseFloat(cuotaTotal.toFixed(2)));
        setTotalInteres((prev) => prev + parseFloat(interes.toFixed(2)));
        setTotalCapital((prev) => prev + parseFloat(capital.toFixed(2)));
      }

      // Calcular la sumatoria de todas las cuotas
      const sumatoriaCuotas = amortizationTable.reduce(
        (acc, cuota) => acc + parseFloat(cuota.cuota),
        0
      );

      // Calcular el nuevo saldo de la primera cuota que es la sumatoria de todas las cuotas menos el último pago
      const saldoPrimeraCuota =
        sumatoriaCuotas - amortizationTable[plazo - 1].cuota;

      // Reemplazar la cuotaTotal de la primera cuota por el saldoPrimeraCuota
      amortizationTable[0].cuotaTotal = saldoPrimeraCuota.toFixed(2);

      // Calcular el resto de las cuotas Totales correctamente
      for (let i = 1; i < plazo; i++) {
        if (i < plazo / 2) {
          amortizationTable[i].cuotaTotal = parseFloat(
            amortizationTable[i - 1].cuotaTotal -
              amortizationTable[plazo - i - 1].cuotaTotal
          ).toFixed(2);
        } else if (i === plazo / 2) {
          amortizationTable[i].cuotaTotal = parseFloat(
            amortizationTable[plazo - i - 1].cuotaTotal -
              amortizationTable[plazo - i - 1].cuota
          ).toFixed(2);
        } else {
          amortizationTable[i].cuotaTotal = parseFloat(
            amortizationTable[i - 1].cuotaTotal -
              amortizationTable[plazo - i - 1].cuota
          ).toFixed(2);
        }
      }

      // Si la última cuotaTotal es diferente a 0, insertar 0
      if (amortizationTable[plazo - 1].cuotaTotal !== 0) {
        amortizationTable[plazo - 1].cuotaTotal = 0.0;
      }

      console.log("sumatoriaCuotas", sumatoriaCuotas);

      return amortizationTable;
    } catch (error) {
      console.error("Error al calcular la amortización:", error);
      // Retornar un valor predeterminado o una tabla de amortización vacía en caso de error
      return [];
    }
  };

  const calculateAmortizationFrances = (monto, plazo, tasaInteres, seguro) => {
    if (seguro === "") {
      seguro = 0;
    }
    setTotalCuota(0);
    setTotalSeguro(0);
    setTotalCuotaTotal(0);
    setTotalInteres(0);
    setTotalCapital(0);
    const amortizationTable = [];
    const tasaMensual = tasaInteres / 100 / 12;
    let saldo = monto;

    for (let i = 1; i <= plazo; i++) {
      let cuota;
      let interes;
      let capital;
      let seguroCuota;
      let cuotaTotal;

      if (sistemaAmortizacion === "aleman") {
        // Cálculos para el sistema alemán
        cuota = interes + capital;

        saldo -= capital;
        seguroCuota = seguro / plazo;
        cuotaTotal = cuota + seguroCuota;
      } else if (sistemaAmortizacion === "frances") {
        // Cálculos para el sistema francés
        interes = saldo * tasaMensual;
        cuota = (monto * tasaMensual) / (1 - Math.pow(1 + tasaMensual, -plazo));
        console.log("cuota", cuota);
        console.log("primer parentesis", monto * tasaMensual);
        console.log(
          "segundo parentesis",
          1 - Math.pow(1 + tasaMensual, -plazo)
        );

        capital = cuota - interes;
        seguroCuota = seguro / plazo;
        cuotaTotal = cuota + seguroCuota;
        saldo -= capital;
      }

      amortizationTable.push({
        numeroCuota: i,
        cuota: cuota.toFixed(2),
        seguro: seguroCuota.toFixed(2),
        cuotaTotal: cuotaTotal.toFixed(2),
        interes: interes.toFixed(2),
        capital: capital.toFixed(2),
        saldo: saldo.toFixed(2),
      });

      setTotalCuota((prev) => prev + parseFloat(cuota.toFixed(2)));
      setTotalSeguro((prev) => prev + parseFloat(seguroCuota.toFixed(2)));
      setTotalCuotaTotal((prev) => prev + parseFloat(cuotaTotal.toFixed(2)));
      setTotalInteres((prev) => prev + parseFloat(interes.toFixed(2)));
      setTotalCapital((prev) => prev + parseFloat(capital.toFixed(2)));
    }

    return amortizationTable;
  };

  const handleCalculate = () => {
    console.log("====>>>" + sistemaAmortizacion);

    sistemaAmortizacion === "aleman"
      ? setAmortizationTable(
          calculateAmortization(monto, plazo, tasaInteres, seguro)
        )
      : setAmortizationTable(
          calculateAmortizationFrances(monto, plazo, tasaInteres, seguro)
        );
  };

  return (
    <>
      <div
        style={{
          background: "linear-gradient(to right, #3b82f6, #2563eb)",
          color: "black",
        }}
      >
        <header
          style={{ padding: "1rem 2rem" }}
          className="flex justify-between items-center"
        >
          <div className="flex items-center">
            <h2 className="text-3xl font-bold animate__animated animate__fadeIn">
              {empresa.institucion}
            </h2>
          </div>
          <div className="flex items-center">
            <div className="cursor-pointer relative rounded-full overflow-hidden mr-4 animate__animated animate__fadeIn"></div>
          </div>
        </header>
      </div>

      <div
        style={{
          maxWidth: "calc(3xl + 4rem)",
          margin: "0 auto",
          background: "#f3f4f6",
          padding: "2rem",
          borderRadius: "1rem",
          boxShadow:
            "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
        }}
      >
        <div className="text-block-callback">
          <h2
            style={{
              color: "black",
            }}
          >
            Calcula tu crédito
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="mb-4">
            <label
              style={{ color: "black" }}
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Monto
            </label>
            <input
              type="number"
              placeholder="Ingrese el monto"
              value={monto}
              onChange={(e) => {
                const value = e.target.value;
                // Si el valor es vacío, establecer el estado a vacío
                if (value === "") {
                  setMonto("");
                } else {
                  // Convertir el valor a un número entero
                  const parsedValue = parseInt(value, 10);
                  // Verificar si el valor es un número válido y está dentro del rango de 0 a 10000
                  if (
                    !isNaN(parsedValue) &&
                    parsedValue >= 0 &&
                    parsedValue <= 10000
                  ) {
                    setMonto(parsedValue);
                  }
                  // Si el valor no es válido, podrías mostrar un mensaje de error o simplemente no actualizar el estado
                }
              }}
              className="input-field"
              max="10000" // Establece el valor máximo permitido
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              style={{ color: "black" }}
            >
              Plazo en Meses
            </label>
            <input
              type="number"
              placeholder="Ingrese el plazo"
              value={plazo}
              onChange={(e) => {
                const value = e.target.value;
                // Si el valor es vacío, establecer el estado a vacío
                if (value === "") {
                  setPlazo("");
                } else {
                  // Convertir el valor a un número entero
                  const parsedValue = parseInt(value, 10);
                  // Verificar si el valor es un número válido y está dentro del rango de 0 a 48
                  if (
                    !isNaN(parsedValue) &&
                    parsedValue >= 0 &&
                    parsedValue <= 48
                  ) {
                    setPlazo(parsedValue);
                  }
                  // Si el valor no es válido, podrías mostrar un mensaje de error o simplemente no actualizar el estado
                }
              }}
              className="input-field"
              max="48" // Establece el valor máximo permitido
            />
          </div>
        </div>
        <button
          style={{
            background: "#3b82f6",
            color: "white",
            borderRadius: "9999px",
            cursor: "pointer",
            transition: "background-color 0.2s",
          }}
          onClick={handleCalculate}
        >
          Calcular
        </button>
        <div
          style={{
            marginTop: "2rem",
            overflowX: "auto",
            borderRadius: "1rem",
            boxShadow:
              "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
          }}
        >
          <table
            ref={tableRef}
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "1px solid #e5e7eb",
              borderRadius: "1rem",
              overflow: "hidden",
            }}
          >
            <thead>
              <tr style={{ background: "#f3f4f6" }}>
                <th
                  style={{
                    padding: "0.75rem 1rem",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Número de cuota (N)
                </th>
                <th
                  style={{
                    padding: "0.75rem 1rem",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Saldo
                </th>
                <th
                  style={{
                    padding: "0.75rem 1rem",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Capital
                </th>
                <th
                  style={{
                    padding: "0.75rem 1rem",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Interés
                </th>
                <th
                  style={{
                    padding: "0.75rem 1rem",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Seguro
                </th>
                <th
                  style={{
                    padding: "0.75rem 1rem",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Cuota
                </th>
                <th
                  style={{
                    padding: "0.75rem 1rem",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Nuevo saldo
                </th>
              </tr>
            </thead>
            <tbody>
              {amortizationTable.map((row, index) => (
                <tr
                  key={index}
                  style={{
                    background: `rgba(209, 213, 219, ${
                      index % 2 === 0 ? "0.5" : "0.75"
                    })`,
                    transition: "all 0.3s",
                    transform: "scale(1)",
                    ":hover": { scale: "1.05" },
                  }}
                >
                  <td style={{ padding: "0.75rem 1rem", textAlign: "center" }}>
                    {row.numeroCuota}
                  </td>
                  <td style={{ padding: "0.75rem 1rem", textAlign: "center" }}>
                    {row.saldo}
                  </td>
                  <td style={{ padding: "0.75rem 1rem", textAlign: "center" }}>
                    {row.capital}
                  </td>
                  <td style={{ padding: "0.75rem 1rem", textAlign: "center" }}>
                    {row.interes}
                  </td>
                  <td style={{ padding: "0.75rem 1rem", textAlign: "center" }}>
                    {row.seguro}
                  </td>
                  <td style={{ padding: "0.75rem 1rem", textAlign: "center" }}>
                    {row.cuota}
                  </td>
                  <td style={{ padding: "0.75rem 1rem", textAlign: "center" }}>
                    {row.cuotaTotal}
                  </td>
                </tr>
              ))}
              <tr style={{ background: "#f9fafb", fontWeight: "bold" }}>
                <td
                  style={{ padding: "0.75rem 1rem", textAlign: "center" }}
                ></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Client;
