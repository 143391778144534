import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import routes from "./components/pages/index";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
function App() {
  return (
    <BrowserRouter>
      <Switch>
        {routes.map((data, idx) => (
          <Route exact path={data.path} component={data.component} key={idx} />
        ))}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
