import React, { Component } from 'react';
import { SingleBlogComponent } from '../../blog';
class MainPost extends Component {
	constructor(props) {
        super(props);
        this.state = {
            tabpost: [
                {
                    id: '1',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '2',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '3',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '4',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '5',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '6',
                    classcategory: 'wrap-one-half services',
                },
            ],
            boxcontent: [
                {
                    id: '1',
                    title: 'Nuestra Historia',
                    description: 'La Caja de Ahorro y Crédito CREDIPLUS Ltda. nace un 23 de septiembre de 2023 mediante registro en la Superintendencia de Economia Popular y Solidaria SEPS, con la finalidad de dar apoyo a los residentes del barrio la Floresta de la ciudad de Ambato, dado que la mayoria son comerciantes ubicados en un sector estratégico como es el Mercado Mayorista, comprometidos con generar oportunidades de trabajo finnciando sus proyectos  ',
                },
         
                {
                    id: '3',
                    title: 'Nuestra Filosofía',
                    description: 'Desde nuestra fundación, nos hemos guiado por principios de solidaridad, cooperación y responsabilidad social. Creemos en el poder del ahorro y el crédito como herramientas fundamentales para el desarrollo económico y social de nuestra comunidad. Por ello, nos esforzamos en ofrecer servicios financieros inclusivos, accesibles y adaptados a las necesidades específicas de nuestros miembros. Nuestra filosofía se basa en los siguientes pilares:',
                },
            ],
            flatlist: [
                {
                    id: '1',
                    text: 'Inclusión Financiera'
                },
                {
                    id: '2',
                    text: 'Desarrollo Comunitario'
                },
                {
                    id: '3',
                    text: 'Responsabilidad Social'
                },
                {
                    id: '4',
                    text: 'Solidaridad y Cooperación'
                },
          
            ],
            flattoggle: [
                {
                    id: '1',
                    title: 'Nuestros Servicios',
                    content: 'Ofrecemos cuentas de ahorro flexibles, créditos personales y comerciales con condiciones favorables, y microcréditos especialmente diseñados para emprendedores y pequeños comerciantes. En CREDIPLUS, nos comprometemos a proporcionar soluciones financieras adaptadas a las necesidades de nuestra comunidad.',
                    classtoggle: 'toggle-title active'
                },
                {
                    id: '2',
                    title: 'Educación Financiera',
                    content: 'En CREDIPLUS, nos dedicamos a empoderar a nuestros miembros a través de la educación financiera. Proporcionamos recursos y asesoramiento personalizado para fortalecer la comprensión de temas como el manejo del presupuesto, estrategias de ahorro efectivas y el uso responsable del crédito. Nuestra misión es brindar las herramientas necesarias para que puedan tomar decisiones financieras sólidas y alcanzar sus objetivos económicos a largo plazo.',
                    classtoggle: 'toggle-title'
                },
                {
                    id: '3',
                    title: 'Compromiso con la Comunidad',
                    content: 'Nos involucramos en programas de apoyo comunitario, iniciativas sostenibles y eventos locales que fortalecen el tejido social de nuestra comunidad. En CREDIPLUS, estamos comprometidos con el bienestar integral de nuestros miembros y el desarrollo sostenible de nuestro entorno.',
                    classtoggle: 'toggle-title'
                },
            ],
            titleplan: [
                {
                    id: '1',
                    title: 'Transformando Vidas a Través del Empoderamiento Financiero'
                }
            ],
            textplan: [
                {
                    id: '1',
                    text: 'En un contexto donde el acceso a servicios financieros suele ser limitado para ciertas comunidades, CREDIPLUS Ltda. se destaca como un faro de esperanza y oportunidad en el barrio La Floresta de Ambato. Desde nuestra fundación en septiembre de 2023, nos hemos comprometido con el empoderamiento económico de nuestros miembros, ofreciendo no solo servicios financieros, sino también educación financiera y asesoramiento personalizado. Creemos firmemente en la inclusión financiera como un medio para romper barreras y generar un impacto positivo en la comunidad. A través de alianzas estratégicas con organizaciones locales y programas de responsabilidad social, trabajamos incansablemente para fortalecer el tejido social y económico de La Floresta. En CREDIPLUS, no solo financiamos proyectos, sino que también construimos sueños y transformamos vidas.'
                },
                
            ]
            
        }
    }
    render() {
        return (
            <>
            <div className="col-md-9 content-tab">
                {
                    this.state.tabpost.map(data =>(
                        <div className="content-inner" key={data.id} >
                            <div className={data.classcategory}>
                                <div className="one-half v1">
                                    <div className="box-content">
                                        {
                                            this.state.boxcontent.map(data =>(
                                                <div key={data.id} >
                                                   <div className="title">{data.title}</div>
                                                    <p>{data.description}</p>
                                                    <div className="dividers dividers-bc-v1"></div> 
                                                </div>
                                            ))
                                        }
                                        <ul className="flat-list">
                                            {
                                                this.state.flatlist.map(data=> (
                                                    <li key={data.id} >{data.text} </li>
                                                ))
                                            }
                                        </ul>
                                    </div>									
                                </div>
                                <div className="one-half v2">
                                    <div className="flexslider s1">
                                        <div className="flat-slides">                            
                                            <ul className="slides">
                                                <li>   
                                                    <img src="images/slides/sv1.png" alt="img" />
                                                </li>
                                                
                                                <li>
                                                    <img src="images/slides/sv2.png" alt="img" />
                                                </li>
                                            </ul> 
                                        </div>
                                    </div>

                                    <div className="flat-accordion style">
                                        {
                                            this.state.flattoggle.map(data =>(
                                                <div className="flat-toggle" key={data.id} >
                                                    <h6 className={data.classtoggle}>{data.title}</h6>
                                                    <div className="toggle-content">
                                                        <p>{data.content}</p>                               
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="dividers dividers-bc-v2"></div>
                                <div className="box-content">
                                    {
                                        this.state.titleplan.map(data =>(
                                            <div key={data.id} className="title">{data.title}</div>
                                        ))
                                    }
                                    {
                                        this.state.textplan.map(data =>(
                                            <div key={data.id} >
                                                <p>{data.text}</p>
                                                <div className="dividers dividers-bc-v3"></div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

						</div>

                    ))
                }
                
            </div>
            <h1>BLOG | NOTICIAS</h1>
            <SingleBlogComponent />

            </>

        )
                                
    }
}

export default MainPost;