import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Featured, Featuredtwo } from '../featured';
import { Offer } from '../../home05';
import { Callback } from '../callback';
class MainServices extends Component {
	constructor(props) {
        super(props);
        this.state = {
            titleServices: [
                {
                    id: 1,
					title: 'NUESTROS SERVICIOS',
					description: 'Aquí encontrarás soluciones financieras diseñadas específicamente para ti. Descubre nuestros créditos, ideales para impulsar pequeños negocios y mejorar tu bienestar personal. También ofrecemos opciones de ahorro adaptadas a tus necesidades, ya sea para respaldar tu negocio o asegurar tu bienestar personal.',
                }
			],
			imagebox: [
				{
					id: '1',
					imgsrc: 'images/imagebox/04.jpg',
					title: 'Creditos',
					description: 'Mutual funds pool money from many investors to purchase broad range of investments, such as stocks.'
				},
				{
					id: '2',
					imgsrc: 'images/imagebox/05.jpg',
					title: 'Ahorros',
					description: 'Mutual funds pool money from many investors to purchase broad range of investments, such as stocks.'
				},
				{
					id: '3',
					imgsrc: 'images/imagebox/06.jpg',
					title: 'Personal Insurance',
					description: 'Mutual funds pool money from many investors to purchase broad range of investments, such as stocks.'
				},
				
			]
        }
    }
    render() {
        return (
			<section className="flat-row pd-services-post">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							{
								this.state.titleServices.map(data =>(
									<div className="title-section center s1" key={data.id} >
										<h2>{data.title} </h2>
										<p className="sub-title-section">{data.description} </p>
									</div>
								))
							}
							<div className="dividers dividers-imagebox"></div>
						</div>
					</div>
					<div className="row">
					<Featured />
					<Callback />
                    <Featuredtwo />
						
					</div>
				</div>
			</section>

				



        );
    }
}

export default MainServices;