import React, { Component } from 'react';
import {Link} from 'react-router-dom'
class TopBar extends Component {
    render() {
        return (
            <div className="top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="flat-infomation">
                                <li className="phone">Contacto: <Link title="phone">0990120709</Link></li>
                                <li className="email">Email: <Link  title="email">info@crediplus.com.ec</Link></li>
                                <li className="facebook">
  <a href="https://www.facebook.com/profile.php?id=61552086374470" title="email">CrediPlus</a>
</li>                            
                            </ul>
                      

                      
                            {/* <div className="flat-questions">
                                <Link to="#" title="" className="questions">Have any questions?</Link>
                                <Link to="#" title="" className="appointment">GET AN APPOINTMENT</Link>
                            </div>
                            <div className="clearfix"></div> */}
                        </div>
                    </div>
                </div>
		    </div>
        );
    }
}

export default TopBar;