// Import the functions you need from the SDKs you need
// import * as firebase from 'firebase';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
// import 'firebase/compat/firestore';

import { getFirestore } from "firebase/firestore/lite";
import { getStorage } from "firebase/storage"; // Importar getStorage

// import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBjdGNMnbmoyRIs1hi7hbvnRWp1iOevPro",
  authDomain: "credipl.firebaseapp.com",
  projectId: "credipl",
  storageBucket: "credipl.appspot.com",
  messagingSenderId: "186528653792",
  appId: "1:186528653792:web:70634625e3fe2e4ac9cb85",
};

let app;

if (firebase.apps.length === 0) {
  app = firebase.initializeApp(firebaseConfig);
} else {
  app = firebase.app();
}

const db = getFirestore(app);
const auth = firebase.auth();
const storage = getStorage(app); // Inicializar storage

export { db, auth, storage };
