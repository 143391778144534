const menus = [
  {
    id: 1,
    name: "Inicio",
    linkmenu: "/",
  },
  {
    id: 5,
    name: "Creditos | Ahorros",
    linkmenu: "/servicios",
  },
  {
    id: 2,
    name: "Comunidad",
    sub: "Our Team",
    linkmenu: "/comunidad",
  },

  {
    id: 3,
    name: "Acerca De Nosotros",
    sub: "Risk Management",
    linkmenu: "/nosotros",
  },

  {
    id: 6,
    name: "Contacto",
    linkmenu: "/contacto",
  },
];

export default menus;
