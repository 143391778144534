import React, { useEffect, useState } from 'react';
import BlogFeatured from './BlogFeatured';
import BlogSlidebar from './BlogSlidebar';
import { auth, db, storage } from '../../../../firebase';
import {
  collection,
  getDocs,
} from 'firebase/firestore/lite';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadBytes,
} from 'firebase/storage';

function Blog() {
  const [blogs, setBlogs] = useState([]);
  
  useEffect(() => {
    const getBlogs = async () => {
      const blogsRef = collection(db, 'blogs');
      const snapshot = await getDocs(blogsRef);
      const blogsList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      //Ordernar por fecha la mas reciente primero
      blogsList.sort((a, b) => {
        return b.fecha.seconds - a.fecha.seconds;
      });
      
      setBlogs(blogsList);

    };

  

    getBlogs();
  }, []);

  return (
    <section className="flat-row flat-news">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title-section left">
              <h2>Noticias | Blog</h2>
            </div>
            {/* <!-- /.title-section --> */}
          </div>
        </div>
        {/* <!-- /.row --> */}
        <div className="row">
          <BlogFeatured datanewBox={blogs.slice(0, 2) || []} />
          <BlogSlidebar datanewBox={blogs.slice(2, 4) || []} />
        </div>
        {/* <!-- /.row --> */}
      </div>
      {/* <!-- /.container --> */}
    </section>
  );
}

export default Blog;
