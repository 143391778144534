import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Benefit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itembox: [
		
				
				{
                    id: 3,
					title: 'Misión',
					classicon: 'icon-benefit people',
					boxcontent: 'Nuestra misión es ser la fuerza impulsora detrás del bienestar económico de nuestros miembros y la comunidad, ofreciendo servicios financieros inclusivos, éticos y adaptados. Nos comprometemos a proporcionar soluciones innovadoras y transparentes, respaldadas por una sólida educación financiera. A través de la participación activa de nuestros miembros, promovemos un modelo cooperativo que va más allá de la transacción financiera, fomentando la equidad, la estabilidad y el desarrollo sostenible en la comunidad que servimos.'
				},
			
				{
                    id: 5,
					title: 'Visión',
					classicon: 'icon-benefit magic',
					boxcontent: 'En los próximos 5 años, la caja de ahorro y crédito CREDIPLUS"  Será reconocida por ser la principal impulsora de la inclusión financiera y el bienestar económico en nuestra comunidad. Aspiramos a ser un referente de transparencia, ética y compromiso social en el sector financiero. Nos dirigimos hacia un futuro donde la tecnología es una aliada clave, proporcionando servicios financieros accesibles y plataformas digitales innovadoras. Nuestra visión es inspirar un cambio significativo, no solo ofreciendo servicios financieros, sino también transformando vidas a través de la educación financiera. Queremos llegar a ser una fuerza motriz en la transformación económica, construyendo un ciclo virtuoso de ahorro, inversión y crecimiento sostenible que beneficie a nuestros miembros y a la sociedad en su conjunto.'
				},
            ]
        }
    }

    render() {
        return (
            <section className="flat-row parallax parallax1 flat-benefit">			
                <div className="container">
                    <div className="row">

                        <div className="col-md-4">
                            <div className="benefit-image">
                                <img src="https://financereact.vercel.app/images/benefit/01.png" alt="financial" />
                            </div>
                        </div>

                        <div className="col-md-8">
                            <div className="row justify-content-center">
                                {this.state.itembox.map(data => (
                                    <div className="col-md-6 iconbox-item" key={data.id}>
                                        <div className="iconbox style3 text-center">
                                            <div className="box-header">
                                                <div className={data.classicon}></div>
                                                <div className="box-title">
                                                    <Link to="#" title="">{data.title}</Link>
                                                </div>
                                            </div>
                                            <div className="box-content">
                                                {data.boxcontent}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        );
    }
}

export default Benefit;
