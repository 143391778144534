import React, { Component } from "react";
import { Link } from "react-router-dom";
class EventBoxs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataEvents: [
        {
          id: 1,
          srcimg: "/images/imagebox/001.jpg",
          title: "Microcrédito",
          description:
            "Destinado para pequeños negocios o microempresas que buscan financiar activos fijos o capital para mejorar o ampliar sus actividades productivas.",
        },
        {
          id: 2,
          srcimg: "https://financereact.vercel.app/images/imagebox/002.jpg",
          title: "De consumo",
          description:
            "Destinado a personas para la adquisición de bienes, pago de servicios, o para responder ante una eventualidad o emergencia.",
        },
      ],
    };
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-4">
          <div className="text-block-callbackk">
            <div className="title-section left">
              <h2>CREDITOS</h2>
            </div>
            <div className="text-callback-content">
              En nuestra institución, ofrecemos soluciones financieras adaptadas
              a tus necesidades. Descubre nuestros créditos diseñados para
              impulsar pequeños negocios y mejorar tu bienestar personal.
            </div>
          </div>
        </div>

        {this.state.dataEvents.map((data) => (
          <div className="col-md-4" key={data.id}>
            <div className="imagebox-item">
              <div className="imagebox style1">
                <div className="imagebox-image">
                  <img src={data.srcimg} alt="financial" />
                </div>
                {/* <!-- /.imagebox-image --> */}

                <div className="imagebox-title">
                  <h3>
                    <Link
                      to="/blog"
                      onClick={() => {
                        window.location.href = "/blog";
                      }}
                      title=""
                    >
                      {data.title}
                    </Link>
                  </h3>
                </div>
                {/* <!-- /.iamgebox-title --> */}
                <div className="imagebox-content">
                  <div className="imagebox-desc">{data.description}</div>
                  <div className="imagebox-button">
                  
                  </div>
                </div>
                {/* <!-- /.imagebox-content --> */}
              </div>
              {/* <!-- /.imagebox --> */}
            </div>
            {/* <!-- /.imagebox-item --> */}
          </div>
        ))}
      </div>
    );
  }
}

export default EventBoxs;
