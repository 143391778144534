import React, { Component } from 'react';
import BoxPost from './BoxPost';
class MainPost extends Component {
	constructor(props) {
        super(props);
        this.state = {
            tabpost: [
                {
                    id: '1',
                    classcategory: 'wrap-main-post about-v1',
                    data:  [
                        {
                            id: '1',
                            srcimg: 'images/about/01.jpg',
                            subtitle:'Desarrollo de Programas Educativos',
                            title: 'Identificación de Necesidades',
                            description: 'Realizar encuestas y entrevistas para comprender las necesidades específicas de educación financiera en la comunidad.',
                            descriptiontwo: "Analizar datos demográficos y socioeconómicos para personalizar los programas según las características de la audiencia.",
                            classdivider: 'dividers dividers-about-post'
                        },
                        {
                            id: '2',
                            srcimg: 'images/about/02.jpg',
                            subtitle:'Desarrollo de Programas Educativos',
                            title: 'Diseño de Contenido',
                            description: 'Desarrollar material educativo claro y accesible que se adapte al nivel de comprensión de la comunidad.',
                            descriptiontwo: "Dividir el contenido en módulos o sesiones temáticas para abordar diferentes aspectos financieros de manera organizada.",
                            classdivider: 'dividers dividers-about-post'
                        },
                        {
                            id: '3',
                            srcimg: 'images/about/03.jpg',
                            subtitle:'Desarrollo de Programas Educativos',
                            title: 'Formatos de Aprendizaje',
                            description: 'Utilizar diversos formatos, como presentaciones, videos, infografías y material impreso, para adaptarse a diferentes estilos de aprendizaje.',
                            descriptiontwo: "Fomentar la participación activa mediante ejercicios prácticos y estudios de caso relevantes.",
                            classdivider: 'dividers dividers-about-post'
                        },
                        {
                            id: '4',
                            srcimg: 'images/about/04.jpg',
                            subtitle:'Desarrollo de Programas Educativos',
                            title: 'Especialización por Temas',
                            description: 'Desarrollar módulos específicos para temas como ahorro, inversión, planificación financiera a corto y largo plazo, y gestión del crédito.',
                            descriptiontwo: "Incluir sesiones detalladas sobre conceptos clave, como tasa de interés, riesgos financieros y beneficios del ahorro.",
                            classdivider: 'dividers dividers-about-post'
                        },
                        {
                            id: '5',
                            srcimg: 'images/about/05.jpg',
                            subtitle:'Desarrollo de Programas Educativos',
                            title: 'Accesibilidad',
                            description: 'Garantizar que los programas sean accesibles para todas las edades y niveles de educación.',
                            descriptiontwo: "Adaptar el contenido para incluir ejemplos y casos que reflejen la realidad de la comunidad.",
                            classdivider: 'dividers dividers-about-post'
                        },
                    ]        
                },
                {
                    id: '2',
                    classcategory: 'wrap-main-post about-v1',
                    data:  [
                        {
                            id: '6',
                            srcimg: 'images/about/06.jpg',
                            subtitle:'Talleres Financieros',
                            title: 'Organización de Eventos',
                            description: 'Planificar y coordinar talleres de manera regular, teniendo en cuenta la disponibilidad y conveniencia de la comunidad.',
                            descriptiontwo: "Utilizar espacios comunitarios, como escuelas, centros comunitarios o cooperativas, para llevar a cabo los talleres.",
                            classdivider: 'dividers dividers-about-post'
                        },
                        {
                            id: '7',
                            srcimg: 'images/about/07.jpg',
                            subtitle:'Talleres Financieros',
                            title: 'Temáticas Específicas',
                            description: 'Diseñar talleres centrados en áreas clave como el ahorro sistemático, la creación de presupuestos familiares y la gestión de deudas.',
                            descriptiontwo: "Adaptar los talleres para abordar las preocupaciones financieras específicas de la comunidad.",
                            classdivider: 'dividers dividers-about-post'
                        },
                        {
                            id: '8',
                            srcimg: 'images/about/08.jpg',
                            subtitle:'Talleres Financieros',
                            title: 'Participación de Expertos',
                            description: 'Invitar a profesionales financieros, asesores o representantes de la cooperativa para compartir conocimientos especializados.',
                            descriptiontwo: "Facilitar sesiones de preguntas y respuestas para aclarar dudas y proporcionar orientación personalizada.",
                            classdivider: 'dividers dividers-about-post'
                        },
                        {
                            id: '9',
                            srcimg: 'images/about/09.jpg',
                            subtitle:'Talleres Financieros',
                            title: 'Materiales Educativos Tangibles',
                            description: 'Proporcionar material impreso, hojas de trabajo y recursos prácticos que los participantes puedan llevarse para reforzar el aprendizaje en casa.',
                            descriptiontwo: "Crear guías de referencia que resuman los conceptos clave discutidos durante los talleres.",
                            classdivider: 'dividers dividers-about-post'
                        },
                        {
                            id: '10',
                            srcimg: 'images/about/10.jpg',
                            subtitle:'Talleres Financieros',
                            title: 'Feedback y Evaluación',
                            description: 'Recoger retroalimentación de los participantes al final de cada taller para evaluar la efectividad y hacer ajustes.',
                            descriptiontwo: "Realizar evaluaciones periódicas del progreso de los participantes para medir el impacto a largo plazo.",
                            classdivider: 'dividers dividers-about-post'
                        },
                        {
                            id: '11',
                            srcimg: 'images/about/11.jpg',
                            subtitle:'Talleres Financieros',
                            title: 'Promoción y Participación Activa',
                            description: 'Utilizar estrategias de marketing para promover los talleres y fomentar la participación de la comunidad.',
                            descriptiontwo: "Diseñar sesiones interactivas que involucren a los participantes, como estudios de caso prácticos y simulaciones financieras.",
                            classdivider: 'dividers dividers-about-post'
                        }
                        
                        
                    ]        
                },
             
            ]
            
        }
    }
    render() {
        return (
			<div className="col-md-9 content-tab">
                {
                    this.state.tabpost.map( data => (
                        <div className="content-inner" key={data.id} >
                            <div className={data.classcategory}>
                                <BoxPost data={data.data}/>
                            </div> 
                        </div>
                    ))
                }
                </div>
        )
                                
    }
}

export default MainPost;