import React, { Component } from 'react';
import { withRouter} from 'react-router-dom'
import { Footer,Header,Slider,  Loader,CarouselClient,  TopBar } from '../layouts/general';
import { Featured, Featuredtwo } from '../layouts/general/featured';
import { Services } from '../layouts/general/services';
import { Project } from '../layouts/general/projects';
import { Callback } from '../layouts/general/callback';
import { Blog } from '../layouts/general/blog';
import Testimonials from '../layouts/home06/Testimonials';
import { Benefit, BlogFeatured, Counter } from '../layouts/home06';

import { Helmet } from 'react-helmet';

class Home01 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Home'
                },
            ],
        };
    }

    render() {
        return (
            <div className="bg-body3">
                <Helmet>
                    <title>Crediplus - Caja de Ahorro y Crédito</title>
                    <meta name="description" content="Descubre nuestras soluciones financieras, proyectos destacados y testimonios. Visítanos ahora para más información." />
                </Helmet>
                <div className="boxed">
                    <TopBar />
                    {this.state.headers.map(data => (
                        <Header data={data} key={data.id} />
                    ))}
                    <Slider />
                    <Benefit />
                    <Blog />
                    <CarouselClient />
                    <Testimonials />
                    <Footer />
                </div>
            </div>
        );
    }
}

export default withRouter(Home01);
