import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NewBox extends Component {
    render() {
        var { datanewBox } = this.props; // Recibe datanewBox como props
      
        return (
            <div className="col-md-4">
                <div className="slidebar-news">
                    <aside className="widget widget-recent-news">
                        <ul className="recent-news">
                            {datanewBox.map(data => (
                                <li key={data.id}>
                                    <div className="thumb">
                                        <span className="overlay-pop"></span>
                                        <Link
                                              onClick={() => {
                                                localStorage.setItem("titulo", data.titulo)
                                                localStorage.setItem("descripcion", data.descripcion)
                                                localStorage.setItem("imagen", data.image)
                                                console.log("titulo", data.titulo)
                                                console.log("descripcion", data.descripcion)
                                                console.log("imagen", data.image)
            
                                                window.location.href="/blog-single"
                                            }}
                                        > {/* Modificado */}
                                            <img src={data.image} alt="" 
                                                style={{maxWidth: '100px'}} />

                                        </Link>
                                        {/* <!-- /.thumb --> */}
                                    </div>
                                    {/* <!-- /.row --> */}
                                    <div className="text">
                                        <h4>
                                            {/* <Link to="/blog-single" title={data.titulo}>{data.descripcion}</Link> */}
                                            <Link
                                               onClick={() => {
                                                localStorage.setItem("titulo", data.titulo)
                                                localStorage.setItem("descripcion", data.descripcion)
                                                localStorage.setItem("imagen", data.image)
                                                console.log("titulo", data.titulo)
                                                console.log("descripcion", data.descripcion)
                                                console.log("imagen", data.image)
            
                                                window.location.href="/blog-single"
                                            }}
                                            >{data.titulo.substring(0, 70)+"..."}</Link>

                                        </h4>
                                        <div className="entry-post">
                                            <p>{data.descripcion.substring(0, 170)+"..."}</p>
                                        </div>
                                    </div>
                                    {/* <!-- /.content-post -->*/}
                                </li>
                            ))}
                        </ul>
                    </aside>
                    {/* <!-- /.widget-recent-news --> */}
                </div>
                {/* <!-- /.slidebar-news --> */}
            </div>
        );
    }
}

export default NewBox;
