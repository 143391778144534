import React, { Component } from 'react';
import { Header, TopBar , Footer,  Loader } from '../layouts/general';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

class Contact01 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Blog',
                }
            ],
            titleheading: [
                {
                    id: '1',
                    title: 'Contacto'
                }
            ],
            breadcrumbs: [
                {
                    id: 1,
                    title: 'Inicio',
                    classicon: 'fa fa-angle-right',
                    aria: 'true'
                },
                {
                    id: 2,
                    title: 'Contacto',
                    classicon: '',
                    aria: ''
                }
            ],
            contactinfo: [
                {
                    id: '2',
                    title: 'Numero De Telefono',
                    info:'0990120709'
                },
                {
                    id: '3',
                    title: 'E-mail',
                    info:'info@crediplus.com.ec'
                }
            ]
        }
    }

    render() {
        return (
            <div className="bg-body">
                <Helmet>
                    <title>Contacto - Crediplus</title>
                    <meta name="description" content="Contacto con Crediplus. Encuentra nuestros detalles de contacto, teléfono y correo electrónico." />
                </Helmet>

                <div className="boxed">
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }

                    <div className="page-title">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="page-title-heading">
                                        {
                                            this.state.titleheading.map(data =>(
                                                <h1 key={data.id} className="h1-title">{data.title}</h1>
                                            ))
                                        }       
                                    </div>
                                    <ul className="breadcrumbs">
                                        {
                                            this.state.breadcrumbs.map(data =>(
                                                <li key={data.id} ><Link to="#" title="">{data.title}<i className={data.classicon} aria-hidden={data.aria}></i></Link></li>
                                            ))
                                        }
                                    </ul>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="flat-row pd-contact-v1">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="contact-info">
                                        {
                                            this.state.contactinfo.map(data =>(
                                                <div className="info info-address" key={data.id} >
                                                    <div className="title">{data.title}</div>
                                                    <p>{data.info}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <img src="images/logo@2x.png" alt="map" />
                                </div>
                            </div>
                        </div>
			        </section>

	     
                    <Footer />
                </div>
            </div>
        );
    }
}

export default Contact01;
