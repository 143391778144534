import React, { Component } from 'react';
class Testimonials extends Component {
	constructor(props) {
        super(props);
        this.state = {
			titleTes: [
				{
					id: 1,
					title: 'Testimonios'
				}
			],
            itemTes: [
                {
					id: 1,
					srcimg: '/images/testimonials/img-01.png',
					content: 'Gracias a CREDIPLUS, pude expandir mi granja. Con su apoyo financiero, pude adquirir más animales y asegurar su alimentación de calidad. Ahora, mi negocio está prosperando y puedo satisfacer mejor la demanda de mis clientes. ',
					author: 'Hiralda Erazo',
					authorinfo: 'Credito Crediplus'
				},
				{
					id: 2,
					srcimg: '/images/testimonials/img-02.png',
					content: 'Gracias a CREDIPLUS, pude financiar la compra de mercancía fresca y variada para mi tienda. Mis clientes están más satisfechos y mi negocio está creciendo. ¡Gracias, CREDIPLUS!',
					author: 'Cesar Morocho',
					authorinfo: 'Credito Crediplus'
				},
				{
					id: 3,
					srcimg: '/images/testimonials/img-03.png',
					content: 'Gracias a CREDIPLUS, pude adquirir nuevas herramientas y repuestos para mi taller de bicicletas. Ahora puedo ofrecer un mejor servicio a mis clientes y seguir creciendo. ¡Gracias, CREDIPLUS!',
					author: 'Amable Espin',
					authorinfo: 'Credito Crediplus'
				},
				{
					id: 4,
					srcimg: '/images/testimonials/img-04.png',
					content: 'CREDIPLUS ha sido un verdadero salvavidas para mí. Sus préstamos accesibles me permitieron iniciar mi pequeño restaurante.',
					author: 'Mario Loor',
					authorinfo: 'Credito Crediplus'
				},
				{
					id: 5,
					srcimg: '/images/testimonials/img-05.png',
					content: 'Gracias a CREDIPLUS, pude financiar la compra de ingredientes y mejorar la presentación de mi restaurante. Ahora tengo más clientes y mi negocio está prosperando. ¡Muchas gracias, CREDIPLUS!',
					author: 'Erika Loor',
					authorinfo: 'Credito Crediplus'
				},
					

            ]
        }
    }
    render() {
        return (
			<section className="flat-row flat-testimonials">
				<div className="container">
					<div className="row">
						<div className="flat-testimonials-title center">
							{
								this.state.titleTes.map(data =>(
									<h2 key={data.id} >{data.title}</h2>
								))
							}
						</div>
					</div>
					<div className="row">
						<div className="col-md-12 col-xs-12">
						<ul className="flat-testimonials-post" data-item="2" data-nav="false" data-dots="false" data-auto="true">
							{
								this.state.itemTes.map(data =>(
									<li className="item" key={data.id} >        					
										<article className="testimonials-post">
											<div className="featured-post">
												<img src={data.srcimg} alt="crediplus testimonio" 
												/>
											</div>
											<div className="content-post">
												<div className="entry-post">
													{data.content}
												</div>
												<div className="testimonials-author">
													<div className="author-name">
														{data.author}
													</div>
													<div className="author-infomation">
														{data.authorinfo}
													</div>
												</div>
											</div>
										</article>     					
									</li>
								))
							}
							
						</ul>
						</div>
					</div>
				</div>
		    </section>
           
        );
    }
}

export default Testimonials;