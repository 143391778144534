import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { Header, TopBar, Footer, Loader } from '../layouts/general';
import { MainServices } from '../layouts/general/services';
import { Callback } from '../layouts/general/callback';

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Pages'
                }
            ],
            titleheading: [
                {
                    id: '1',
                    title: 'Services Grid'
                }
            ],
            breadcrumbs: [
                {
                    id: 1,
                    title: 'Inicio',
                    classicon: 'fa fa-angle-right',
                    aria: 'true'
                },
                {
                    id: 2,
                    title: 'Servicios',
                    classicon: '',
                    aria: ''
                }
            ]
        }
    }

    render() {
        return (
            <div className="bg-body3">
                <Helmet>
                    <title>Servicios - Crediplus</title>
                    <meta name="description" content="Aquí encontrarás soluciones financieras diseñadas específicamente para ti. Descubre nuestros créditos, ideales para impulsar pequeños negocios y mejorar tu bienestar personal. También ofrecemos opciones de ahorro adaptadas a tus necesidades, ya sea para respaldar tu negocio o asegurar tu bienestar personal." />
                    <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Servicios",
                        "description": "Aquí encontrarás soluciones financieras diseñadas específicamente para ti. Descubre nuestros créditos, ideales para impulsar pequeños negocios y mejorar tu bienestar personal. También ofrecemos opciones de ahorro adaptadas a tus necesidades, ya sea para respaldar tu negocio o asegurar tu bienestar personal."
                    }
                    `}
                    </script>
                </Helmet>

                <div className="boxed">
                    {/* <Loader /> */}
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }

                    <div className="page-title">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="page-title-heading">
                                        <h1 className="h1-title">Servicios</h1>
                                    </div>
                                    <ul className="breadcrumbs">
                                        {
                                            this.state.breadcrumbs.map(data => (
                                                <li key={data.id}>
                                                    <Link to="#" title={data.title}>
                                                        {data.title}<i className={data.classicon} aria-hidden={data.aria}></i>
                                                    </Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <MainServices />

                    <Footer />
                </div>
            </div>
        );
    }
}

export default Services;
