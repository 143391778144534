import React, { Component } from "react";
import { Link } from "react-router-dom";
class EventBoxs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataEvents: [
        {
          id: 1,
          srcimg: "/images/imagebox/003.jpg",
          title: "A la vista",
          description:
            "Es un sistema que permite incrementar el capital efectivo, en base a su capacidad de ahorro, al mantener el dinero a resguardo de Crediplus.",
        },
        {
          id: 2,
          srcimg: "images/imagebox/3foto.png",
          title: "Programado",
          description:
            "Destinado a las personas que busquen colocar un monto de capital a un interes mayor al mercado ordinario, en un periodo de tiempo determinado, para cumplir con un propósito específico. A mayor capital, y a mayor tiempo; mayor el rendimiento financiero.",
        },
      ],
    };
  }

  render() {
    return (
      <div className="row">
        {this.state.dataEvents.map((data) => (
          <div className="col-md-4" key={data.id}>
            <div className="imagebox-item">
              <div className="imagebox style1">
                <div className="imagebox-image">
                  <img src={data.srcimg} alt="financial" />
                </div>
                {/* <!-- /.imagebox-image --> */}

                <div className="imagebox-title">
                  <h3>
                    <Link
                      to="/blog"
                      onClick={() => {
                        window.location.href = "/blog";
                      }}
                      title=""
                    >
                      {data.title}
                    </Link>
                  </h3>
                </div>
                {/* <!-- /.iamgebox-title --> */}
                <div className="imagebox-content">
                  <div className="imagebox-desc">{data.description}</div>
                  <div className="imagebox-button">
                    {/* <Link
                      to="/blog"
                      onClick={() => {
                        window.location.href = "/blog";
                      }}
                      title=""
                    >
                      read more
                    </Link> */}
                  </div>
                </div>
                {/* <!-- /.imagebox-content --> */}
              </div>
              {/* <!-- /.imagebox --> */}
            </div>
            {/* <!-- /.imagebox-item --> */}
          </div>
        ))}
        <div className="col-md-4">
          <div className="text-block-callbackk">
            <div className="title-section left">
              <h2>AHORROS</h2>
            </div>
            <div className="text-callback-content">
              Descubre nuestras opciones de ahorro diseñadas para tus
              necesidades, ya sea para respaldar tu negocio o asegurar tu
              bienestar personal.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EventBoxs;
