import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class TopFooter extends Component {
    render() {
        return (
            <div className="widget-infomation">
                <ul className="infomation-footer">
                    <li><i className="fa fa-envelope" aria-hidden="true"></i><Link to="#" title="">info@crediplus.com.ec</Link></li>
                    <li><i className="fa fa-phone" aria-hidden="true"></i><Link to="#" title="">0990120709</Link></li>
                    <li><i className="fa fa-clock-o" aria-hidden="true"></i><Link to="#" title="">Lunes a Viernes de 08h a 18h | Sábado de 08h a 13h00</Link></li>
                </ul>
                {/* <!-- /.infomation-footer --> */}
            </div>
        /* <!-- /.widget-infomation --> */
        );
    }
}

export default TopFooter;