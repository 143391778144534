import React, { Component } from "react";
import { Link } from "react-router-dom";
class BlogFeatured extends Component {
  render() {
    const { datanewBox } = this.props; // Recibe datanewBox como props

    console.log("ACA", datanewBox);
    //Si es mayor de 3 elementos, se corta el array
    if (datanewBox.length > 2) {
      //Traer solo los tres mas recientes por fecha
      datanewBox.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));

      datanewBox.length = 2;
    }

    datanewBox.map(
      (data) => {
        //Sacar el day y el month de la fecha 14 de junio de 2024, 7:37:50 p.m. UTC-5
        //Pasar de Timestamp(seconds=1718412017, nanoseconds=92000000) a 14 de junio de 2024

        data.fecha = new Date(data.fecha.seconds * 1000).toLocaleString(
          "es-ES",
          {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }
        );

        //diccionario de meses 3 primeras letras
        const monthNames = [
          "ene",
          "feb",
          "mar",
          "abr",
          "may",
          "jun",
          "jul",
          "ago",
          "sep",
          "oct",
          "nov",
          "dic",
        ];
        //Sacar el mes en letras
        const month = data.fecha.substring(3, 5);
        //Sacar el mes en letras
        const monthS = data.fecha.replace(
          month,
          monthNames[parseInt(month) - 1]
        );

        //Sacar solo el mes de months
        const monthS2 = monthS.substring(3, 6).toUpperCase();

        //Sacar el day solo el numero

        data.day = data.fecha.substring(0, 2);
        data.month = monthS2;
      }

    );

      //Ordenar por fecha mas recientem primero
        



    return (
      <div>
        {datanewBox.map((data) => (
          <div className="col-md-4" key={data.id}>
            <article className="post style2">
              <div className="featured-post">
                <Link
                  onClick={() => {
                    localStorage.setItem("titulo", data.titulo);
                    localStorage.setItem("descripcion", data.descripcion);
                    localStorage.setItem("imagen", data.image);
                    console.log("titulo", data.titulo);
                    console.log("descripcion", data.descripcion);
                    console.log("imagen", data.image);

                    window.location.href = "/blog-single";
                  }}
                  title=""
                  className="post-image"
                >
                  <img src={data.image} alt="financial" />
                </Link>
                <ul className="post-date">
                  <li className="day">{data.day}</li>
                  <li className="month">{data.month}</li>
                </ul>
              </div>
              {/* <!-- /.featured-post --> */}
              <div className="content-post">
                
                <div className="entry-post">
                  {/* Titulo en negritas y responsive */}
                  <h4 
                  style={{
                    fontSize: '18px',
                    margin: '0 0 10px 0',
                    color: '#333',
                    lineHeight: '1.5',
                    height: '3em', // Dos líneas de 1.5em cada una
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    textOverflow: 'ellipsis'
                  }}
                  >
                    <Link
                      onClick={() => {
                        console.log("aqui click");
                        localStorage.setItem("titulo", data.titulo);
                        localStorage.setItem("descripcion", data.descripcion);
                        localStorage.setItem("imagen", data.image);

                        window.location.href = "/blog-single";
                      }}
                      title=""
                    >
                      {data.titulo}
                    </Link>
                  </h4>
                  {/* <p>{data.descripcion}</p> */}

                  {/* Mostrar solo los primeros 30  caracteres */}
                  <p
                  style={{
                    margin: '0 0 10px 0',
                    color: '#666',
                    fontSize: '14px',
                    lineHeight: '1.5',
                    height: '9em', // Seis líneas de 1.5em cada una
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 6,
                    textOverflow: 'ellipsis'
                  }}
                  >
                    {data.descripcion}...</p>
                </div>
              </div>
              {/* <!-- /.content-post --> */}
            </article>
            {/* <!-- /.post --> */}
          </div>
        ))}
      </div>
    );
  }
}

export default BlogFeatured;
