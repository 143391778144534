import React, { Component } from 'react';
import { Link } from "react-router-dom";
class BoxPost extends Component {
    constructor(props) {
        super(props); // Llamada a super(props) debe estar antes de cualquier otra cosa en el constructor
        const { data } = this.props; // Accede a props después de llamar a super(props)
        this.state = {
            infobox: data
        };
    }
    render() {
        return (
            <div>
                {
                    this.state.infobox.map(data =>(
                        <div key={data.id} >
                        <article className="entry">
                            <div className="feature-post">                                    
                                <Link to="#">
                                    <img src={data.srcimg} alt="img" />
                                </Link>                                    
                            </div>
                            <div className="content-post">
                                <div className="position">{data.subtitle}</div>	
                                <h3 className="title-post"><Link to="#">{data.title}</Link></h3>
                                <div className="entry-post">
                                    <li>{data.description}</li>
                                    <br></br>
                                    <li>{data.descriptiontwo}</li>

                                </div>
                              
                            </div>
                        </article>
                         <div className={data.classdivider}></div>
                        </div>
                    ))
                }
                
            </div>
        )
                                
    }
}

export default BoxPost;