import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { db } from "../../../firebase"; // Assuming you have initialized Firestore in firebase.js
import { collection, getDocs } from "firebase/firestore/lite";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage";

const SingleBlogComponent = () => {
  const [pagination, setPagination] = useState([
 
  ]);

  console.log("acaaaa");
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const getBlogs = async () => {
      const blogsRef = collection(db, "blogs");
      const snapshot = await getDocs(blogsRef);
      const blogsList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

    //   Maximo 40 caracteres en descripcion

    blogsList.sort((a, b) => {
      return b.fecha.seconds - a.fecha.seconds;
  });

        blogsList.map(data => {

          //Ordenar por fecha la mas reciente primero
       

          //Sacar el day y el month de la fecha 14 de junio de 2024, 7:37:50 p.m. UTC-5
          //Pasar de Timestamp(seconds=1718412017, nanoseconds=92000000) a 14 de junio de 2024
  
          data.fecha = new Date(data.fecha.seconds * 1000).toLocaleString("es-ES", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
          });
  
          //diccionario de meses 3 primeras letras
          const monthNames = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];
          //Sacar el mes en letras
          const month = data.fecha.substring(3, 5);
          //Sacar el mes en letras
          const monthS = data.fecha.replace(month, monthNames[parseInt(month) - 1]);
  
          //Sacar solo el mes de months
          const monthS2 = monthS.substring(3, 6).toUpperCase();
  
          //Sacar el day solo el numero
  
  
          data.day = data.fecha.substring(0, 2);
          data.month =monthS2
      })

      


      console.log("LISTA ORDENADA", blogsList);

      setBlogs(blogsList);
    };
    console.log("acaaaa");

    getBlogs();


  }, []);
  return (
    <section className="main-content blog-single-post">
      <div className="container">
        <div className="row">
          <div className="post-wrap">
            {blogs.map((data) => (
              <div className="col-md-4" key={data.id}
                style={{marginBottom: '40px'}}
              >
                <article className="post">
                  <div className="featured-post">
                    <Link
                        onClick={() => {
                            localStorage.setItem("titulo", data.titulo)
                            localStorage.setItem("descripcion", data.descripcion)
                            localStorage.setItem("imagen", data.image)
                            console.log("titulo", data.titulo)
                            console.log("descripcion", data.descripcion)
                            console.log("imagen", data.image)

                            window.location.href="/blog-single"
                        }}
                      title=""
                      className="post-image"
                    >
                      <img src={data.image} alt="img"
                        style={{maxHeight: '300px'}}
                      />
                    </Link>
                    <ul className="post-date">
                      <li className="day">{data.day}</li>
                      <li className="month">{data.month}</li>
                    </ul>
                  </div>

                  {/* ESTA BIEN*/}

                  <div className="content-post">
                  <h4 style={{
                fontSize: '18px',
                margin: '0 0 10px 0',
                color: '#333',
                lineHeight: '1.5',
                height: '3em', // Dos líneas de 1.5em cada una
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                textOverflow: 'ellipsis'
              }}>
                {data.titulo}
              </h4>
                    <div className="entry-post">
                    <p style={{
                margin: '0 0 10px 0',
                color: '#666',
                fontSize: '14px',
                lineHeight: '1.5',
                height: '9em', // Seis líneas de 1.5em cada una
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 6,
                textOverflow: 'ellipsis'
              }}>
                {data.descripcion}
              </p>
                      <div className="more-link">
                        <Link 
                          onClick={() => {
                            localStorage.setItem("titulo", data.titulo)
                            localStorage.setItem("descripcion", data.descripcion)
                            localStorage.setItem("imagen", data.image)
                            console.log("titulo", data.titulo)
                            console.log("descripcion", data.descripcion)
                            console.log("imagen", data.image)
                            window.location.href="/blog-single"
                        }}
                        >
                          Leer Más
                        </Link>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="dividers dividers-pagination"></div>
            <div className="blog-single-pagination">
              <ul className="flat-pagination">
                {pagination.map((data) => (
                  <li key={data.id}>
                    <Link to="#" className={data.class} title="">
                      {data.numb}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SingleBlogComponent;
