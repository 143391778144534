import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { Header, TopBar , Footer } from '../layouts/general';
import Team from "../layouts/aboutus/Team";

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Pages'
                }
            ]
        }
    }

    render() {
        return (
            <div className="bg-body3">
                <Helmet>
                    <title>Comunidad - Crediplus</title>
                    <meta name="description" content="Descubre más sobre nuestra comunidad y cómo trabajamos juntos para lograr grandes cosas." />
                </Helmet>
                <div className="boxed">
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }

                    <div className="page-title">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="page-title-heading">
                                        <h1 className="h1-title">Comunidad</h1>
                                    </div>
                                    <ul className="breadcrumbs">
                                        <li><Link to="/" title="Inicio">Inicio<i className="fa fa-angle-right" aria-hidden="true"></i></Link></li>
                                        <li><Link to="/about-us" title="Comunidad">Comunidad<i className="fa fa-angle-right" aria-hidden="true"></i></Link></li>
                                    </ul>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Team />
                    
                    <Footer />
                </div>
            </div>
        );
    }
}

export default AboutUs;
